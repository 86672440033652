.hero-section {
  height: 60vh;
}

.section--0 {
  padding: 20rem 0;
}
.section--2,
.section--4 {
  background-color: var(--light-bg);
}
.box-container {
  display: flex;
  justify-content: center;
}
.headline-box {
  max-width: 70rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.testemonial {
  margin-bottom: 5rem;
}
.testemonial p:nth-child(2) {
  font-weight: 500;
}

.section--3 img {
  height: 40rem;
  width: 100%;
  object-fit: cover;
  margin-bottom: 3rem;
}
.section--3 p {
  margin-bottom: 3rem;
}
.section--3 .btn {
  display: inline;
}
.youtube-link {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
}
.youtube-link ion-icon {
  margin-top: 0.7rem;
  margin-right: 1rem;
}
.youtube-link:hover ion-icon {
  color: red;
}
/* section 4 */

.section--service-list {
  list-style: none;
  margin-bottom: 13rem;
}
.section--service-item {
  font-size: 5rem;
  font-weight: 700;

  color: black;
  border-left: 1px solid black;
  padding-left: 3rem;
}

.sevice-description {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.service-headlines {
  width: 80%;
  margin-right: 5rem;
}
.service-headlines h1 {
  margin-bottom: 1rem;
}
.sevice-description button {
  width: 19rem;
}
/* section 5 */
.section--5 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2.5rem;
}

.section--5 img {
  height: 30rem;
  width: 30rem;
  object-fit: cover;
  object-position: center;
}
.c-s-headlines p {
  margin-bottom: 4rem;
}

.slider-container {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.31);
  overflow: hidden;
  position: relative;
}

.section-h {
  margin: 20rem 0;
}

.box-item {
  width: 100%;
  height: 100%;
  position: relative;
}

.section-headline {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  width: 100%;
  height: 100%;
}
.section-headline > h1 {
  text-align: center;
  text-align: center;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 1.1;
  text-shadow: 2px 2px 8px rgba(0, 0, 0);
  margin-top: 0.5rem;
}

@media screen and (max-width: 1200px) {
  .section--2,
  .section--3,
  .section--4,
  .section--5 {
    padding-right: 4rem;
    padding-left: 4rem;
  }
}
@media screen and (max-width: 900px) {
  .sevice-description {
    flex-direction: column;
    align-items: start;
  }
  .service-headlines .bodytext {
    margin-bottom: 5rem;
  }
}
@media screen and (max-width: 800px) {
  .section--5 {
    flex-direction: column;
  }
  .section--5 img {
    margin-bottom: 5rem;
  }
}
@media screen and (max-width: 600px) {
  .section--2,
  .section--3,
  .section--4,
  .section--5 {
    padding-right: 2rem;
    padding-left: 2rem;
  }
  .hero-section {
    height: 90vh;
  }
  .section--3 img {
    height: 30rem;
  }
  .service-headlines {
    width: 100%;
  }
  .service-link-btn {
    align-self: center;
  }
  .c-s-link {
    display: flex;
    justify-content: center;
  }
}
@media screen and (max-width: 400px) {
  .section--3 img {
    height: 20rem;
  }
  .section--service-item {
    margin-left: 0;
  }
  .center-element {
    display: flex;
  }
}
