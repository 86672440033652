.surface-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.surface-title {
  align-self: start;
  width: 100%;
}
.surface-section ul {
  margin-left: 3rem;
  padding-top: 0;
  margin-bottom: 3rem;
}

.bullet-pt {
  margin-bottom: 1rem;
}
.img-box {
  max-width: 100rem;
  height: auto;
  padding: 0 1rem;
  margin-bottom: 2rem;
}
.img-box img {
  width: 100%;
}
.text-box {
  max-width: 100rem;
  padding: 2rem;
  margin-bottom: 8rem;
}
.text-box h2 {
  font-size: 2.5rem;
}
.text-box ul {
  margin-left: 1rem;
  margin-top: 2rem;
}

.sub-headline {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 2rem;
}

.chart-section {
  background-color: #4b4b4b;
  width: 100%;
  margin-bottom: 10rem;
  padding: 10rem 0 10rem;
}
.chart-section h1 {
  color: #fff;
  font-size: 3rem;
  text-align: center;
  padding-bottom: 7rem;
  font-weight: 600;

  letter-spacing: 1px;
}
.chart-section .container {
  max-width: 90rem;
}

.img-surface-gallery {
  padding: 1rem;
}

.colours-links {
  position: relative;
  width: 13rem;
  height: 13rem;
  background-color: transparent;
  border: none;
  margin-bottom: 1.6rem;
  margin-left: 1.6%;
}

.colours-links img {
  width: 100%;
  height: 100%;

  object-fit: cover;
  object-position: left;
  border-radius: 50%;
  border: 1px solid #fff;
}

.colours-links::before,
.colours-links::after {
  --scale: 0;
  --arrow-size: 10px;
  --tooltip-color: #fff;

  position: absolute;
  top: -2%;
  left: 50%;
  transform: translateX(-50%) translateY(var(--translate-y, 0))
    scale(var(--scale));
  transition: 150ms transform;
  transform-origin: bottom center;
}

.colours-links::before {
  --translate-y: calc(-100% - var(--arrow-size));
  content: attr(data-tooltip);

  border-top: 0.3px solid #4b4b4b;
  border-left: 0.3px solid #4b4b4b;
  border-right: 0.3px solid #4b4b4b;
  color: #222;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.1;
  /* text-transform: uppercase; */
  padding: 1em;
  text-align: center;

  height: 3.7em;
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  max-width: 130%;
  background: var(--tooltip-color);
}

.colours-links:hover::before,
.colours-links:hover::after {
  --scale: 1;
}
.colours-links::after {
  --translate-y: calc(-1 * var(--arrow-size));
  content: "";
  border: var(--arrow-size) solid transparent;
  border-top-color: var(--tooltip-color);
  transform-origin: top center;
}
@media screen and (max-width: 435px) {
  .colours-links {
    width: 12rem;
    height: 12rem;
  }
}

@media screen and (max-width: 400px) {
  .colours-links {
    width: 15rem;
    height: 15rem;
    margin-left: 1.6rem;
  }
}
