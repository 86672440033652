.searchfield-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.searchfield {
  font-size: 1.8rem;
  font-weight: 500;
  border: 1.5px solid black;
  border-radius: 0%;
  background-color: #fff;
  font-family: inherit;
  padding: 1.5rem 1.8rem;
  height: 7rem;
  width: 40%;
}

/* .searchfield-container ion-icon {
  display: block;
  font-size: 3rem;
  margin-left: 1rem;
  border: 1px solid #4b4b4b;
  padding: 1.9rem 1.6rem;
} */
.search-text {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.3rem 1.6rem;
  margin: 0 1rem 0 0;

  font-size: 1.8rem;
  font-weight: 200;
}
@media screen and (max-width: 960px) {
  .searchfield {
    width: 60%;
  }
}
@media screen and (max-width: 600px) {
  .searchfield {
    font-size: 1.6rem;
  }
  .searchfield {
    height: 5rem;
    width: 85%;
  }
  .searchfield-container ion-icon {
    font-size: 2.5rem;
    padding: 1.1rem 1.3rem;
  }
}
