.header {
  position: sticky;
  margin: 0;

  top: 0;
  /* height: 10rem; */
  width: 100vw;

  opacity: 0.9;

  background-color: rgb(0, 0, 0);
  z-index: 10;
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 10rem;
  padding: 0 3rem;
}
.navbar-logos {
  display: flex;

  align-items: center;
}
.logo-icon {
  height: 5rem;
  margin-right: 1.5rem;
}
.logo-name {
  height: 5rem;
}

.nav-list {
  display: flex;
  list-style: none;
  gap: 2.5rem;
}
.nav-items {
  white-space: nowrap;
}

.nav-link {
  font-size: 1.6rem;
  letter-spacing: 0.063em;

  font-weight: 300;
  text-decoration: none;

  color: var(--light-grey);
  text-align: center;
  padding: 0 0 1rem 0;

  border-style: solid;
  border-width: 0px 0px 0.05px 0px;
  border-color: transparent;
  font-family: var(--font-text);

  transition: 250ms;
}

.nav-link:hover {
  color: #f7f7f7;
  transition: 250ms;
}
.nav-link.active {
  border-width: 0px 0px 1px 0px;
  border-color: #fff;
  color: #f7f7f7;
  transition: 250ms;
}

.menu-icon {
  display: none;
  cursor: pointer;
  z-index: 6;
}

.menu-icon ion-icon {
  color: #fff;
  font-size: 3rem;
}
.menu-icon:hover ion-icon {
  transform: none;
  color: #fff;
}

@media screen and (max-width: 1030px) {
  .logo-icon {
    height: 4.4rem;
    margin-right: 1.5rem;
  }

  .logo-name {
    height: 4.2rem;
  }
  .nav-list {
    gap: 2rem;
  }
}
/* mobile menu navbar */

@media screen and (max-width: 960px) {
  .menu-icon {
    display: block;
  }
  ion-icon[name="close-outline"] {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }
  .navbar {
    padding: 0 1rem;
    height: 8rem;
  }

  .nav-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100vh;
    gap: 3rem;
    padding-top: 10rem;
    position: absolute;
    top: 0;
    left: -100vw;

    background-color: black;

    transition: all 0.5s ease-in-out;
  }

  .nav-link {
    height: 100%;
    width: 95vw;
    border-color: #ffffff55;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 2rem;
    padding: 0 0 2rem 2rem;
    margin: 0 1rem;
  }
  .active {
    top: 0;
    left: 0;
  }
  .nav-link.active {
    border-width: 0px 0px 0.05px 0px;
    border-color: #ffffff55;
  }
  .logo-icon {
    height: 4rem;
  }

  .logo-name {
    height: 3.8rem;
  }
}
