.section-services {
  background-color: var(--light-bg);
  padding: 10rem 0 20rem 0;
  overflow: hidden;
}

.image-design img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.services-container {
  height: 30rem;
}
.design-container {
  width: 50%;
  margin-bottom: 16rem;
}
.design-container ul li:last-child {
  margin-bottom: 5rem;
}
.card-container {
  max-width: 120rem;
  margin: 0 auto;
}
/* grid */
/* ------------- */

.grid {
  display: grid;
  gap: 2.4rem;
}

.grid--3-cols {
  grid-template-columns: repeat(3, 1fr);
}

.service-card {
  max-width: 35rem;
  margin: 0 auto;
}

.service-card-text {
  height: 30rem;
  width: 100%;

  border-top: none;
  position: relative;
}
.service-card-text:last-child {
  height: 42rem;
}

.service-card-title {
  font-size: 5rem;
  font-weight: 700;
  color: black;

  margin: 6rem 0;
  border-left: 1px solid black;
  padding-left: 3rem;
}

.service-card-image {
  display: flex;
  flex-direction: column;
  height: 35rem;
  width: 35rem;
  object-fit: cover;
  object-position: center;
}
.number {
  position: absolute;
  font-size: 12.5rem;
  top: -5.3rem;
  left: 1rem;
  color: #222;
}
.number::after {
  content: ".";
  margin-left: -0.5rem;
  font-size: 7.5rem;
  color: var(--line-color);
}
.service-name {
  font-size: 4rem;
  text-align: center;
  padding: 4rem 0;

  text-transform: uppercase;
  font-weight: 300;
  color: #222;
}

.service-list-items {
  list-style: none;
}
.service-list-items-fabrication {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.service-list-item {
  padding-left: 0.5rem;
  color: #222;
  margin-bottom: 1.5rem;
  font-size: 1.8rem;
  font-weight: 300;
  display: flex;
  align-items: center;
  line-height: 1.2;
}
.service-list-items-fabrication-cl .service-list-item {
  margin-bottom: 0;
}
.service-list-item ion-icon,
.fabrication-icons {
  color: black;

  margin-right: 1.5rem;
}
.fabrication-icons {
  width: 4.5rem;
  height: 5rem;
}
.installation-icons {
  width: 30%;
  margin-right: 1.5rem;
}
.installation-icons-1 {
  margin-top: 2rem;
}

.service-list-items-fabrication-cl {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
@media screen and (max-width: 1200px) {
}
@media screen and (max-width: 950px) {
  .service-card-title {
    font-size: 4rem;
    padding-left: 2rem;
  }
  .card-container {
    padding: 0;
  }
  .grid--3-cols {
    grid-template-columns: repeat(1, 1fr);
  }
  .design-container {
    width: 70%;
    margin-bottom: 10rem;
  }
  .service-card-text-fab {
    margin-bottom: 10rem;
  }
}
@media screen and (max-width: 540px) {
  .design-container {
    width: 100%;
  }
}
