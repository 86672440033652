.slider-container {
  width: 100%;
  position: relative;
}
.intro-slide {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  transition-delay: 1s;
}

.intro-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 10% 50%;
}
.active {
  z-index: 1;
  opacity: 1;
  transition-delay: 0s;
}

.project-description {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  right: 5%;
  width: 40rem;
  padding: 3rem;
  background-color: var(--text--bg);
  opacity: 0;
  animation: slide-up 1s ease 1s;
  animation-fill-mode: forwards;
  visibility: hidden;
}
@keyframes slide-up {
  0% {
    visibility: visible;
    right: 5%;
  }
  100% {
    visibility: visible;
    right: 10%;
  }
}

.project-description:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
.project-description h1 {
  white-space: pre-line;
  color: #fff;

  margin-bottom: 3.5rem;
  border-bottom: none;
}

.project-details {
  font-size: 2rem;
  line-height: 1.4;
  font-weight: 600;
  letter-spacing: 1.4px;
  color: #fff;
  margin-bottom: 2rem;
}
.project-details-2 {
  font-size: 1.6rem;
  letter-spacing: 1px;
  font-weight: 300;
  margin-left: 1rem;
}

.active .project-description {
  opacity: 1;
}
.dots-container {
  position: absolute;
  bottom: 1.8rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  z-index: 2;
}
.dots-container .dot {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 1px solid #fff;
  background-color: rgba(0, 0, 0, 0.052);
  box-shadow: 0 0 0.2rem 0 rgba(0, 0, 0, 0.318);
  margin: 0 5px;
  position: relative;
}

.dots-container .dot.active {
  background: #fff;
  border: 2px solid #fff;
  box-shadow: 0 0 0.2rem 0 rgba(0, 0, 0, 0.318);
}

@media (min-width: 768px) {
  .dots-container {
    bottom: 2rem;
  }
  .dots-container .dot {
    width: 0.8rem;
    height: 0.8rem;
  }
}
@media screen and (max-width: 600px) {
  .project-description {
    top: 45%;
    transform: translateY(-50%);
    width: 80%;
  }
  .dots-container {
    bottom: 4rem;
  }
}
@media screen and (max-width: 385px) {
  .project-description h1 {
    font-size: 4rem;
  }
  .project-details {
    font-size: 1.8rem;
  }
  .project-details-2 {
    font-size: 1.4rem;
  }
}
