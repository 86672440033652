.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(7rem, 1fr));
  grid-gap: 2rem;
  padding: 1rem 0.5rem;
  margin-bottom: 10vh;
}

.colours-surface-links {
  position: relative;
}
.colours-surface-links img {
  inline-size: 100%;
}

.colours-surface-links::before,
.colours-surface-links::after {
  position: absolute;
  --scale: 0;
  --arrow-size: 10px;
  --tooltip-color: #222;
  top: -0.2rem;
  left: 50%;
  transform: translateX(-50%) translateY(var(--translate-y, 0))
    scale(var(--scale));
  transition: 150ms transform;
  transform-origin: bottom center;
}

.colours-surface-links::before {
  position: absolute;
  --translate-y: calc(-100% - var(--arrow-size));
  content: attr(data-tooltip);
  color: #fff;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.2;
  padding: 1.4em;
  text-align: center;
  height: 3.7em;
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  letter-spacing: 0.03em;

  background: var(--tooltip-color);
}
.colours-surface-links:first-child::before {
  max-width: 150%;
}
.colours-surface-links:last-child::before {
  max-width: 180%;
}
.colours-surface-links:hover::before,
.colours-surface-links:hover::after {
  --scale: 1;
}
.colours-surface-links::after {
  --translate-y: calc(-1 * var(--arrow-size));
  content: "";
  border: var(--arrow-size) solid transparent;
  border-top-color: var(--tooltip-color);
  transform-origin: top center;
}
.cover-name {
  display: none;
}
.image-section-display {
  position: absolute;
}

.sec-headline {
  margin-top: 50vh;
}

@media screen and (max-width: 1200px) {
  .gallery {
    padding: 1rem 2rem;
  }
}
@media screen and (max-width: 960px) {
  .gallery {
    grid-template-columns: repeat(auto-fit, minmax(13rem, 1fr));
    padding: 1rem 1.5rem;
  }

  .sec-headline {
    margin-top: 40vh;
  }
  .colours-surface-links {
    margin-bottom: 2rem;
  }
  .colours-surface-links img {
    margin-bottom: 0.5rem;
  }
  .cover-name {
    display: block;
    font-size: 1.5rem;

    font-weight: 600;

    padding-left: 0.2em;
    color: #222;
  }
  .colours-surface-links {
    text-decoration: none;
  }
}
@media screen and (max-width: 600px) {
  .image-display {
    display: none;
  }
  .sec-headline {
    margin-top: 8rem;
  }
}
