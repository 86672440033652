.colour-section {
  min-height: 65vh;
  max-width: 80rem;
  margin: 0 auto;
  margin-top: 5rem;
  padding: 2rem;
}

.colour-headline {
  font-size: 4rem;
  letter-spacing: 0px;
}

.colour-section p {
  margin-bottom: 2rem;
}

.colour-img-box {
  height: 25rem;
  margin-bottom: 5rem;
  width: 100%;
}
.img-colour {
  border: 0.1px solid rgba(0, 0, 0, 0.247);
}
.colour-img-box img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: left;
}

.colour-details {
  color: black;
  margin-left: 2rem;
}
.colour-name {
  font-size: 2.7rem;
  font-weight: 600;
  margin-bottom: 5rem;
}
.product-specification {
  padding-bottom: 4rem;
}

.product-specification-title {
  font-size: 2.3rem;
  font-weight: 300;
  margin-bottom: 2rem;
}
.product-specification-list {
  font-size: 1.8rem;
  margin-left: 1.5rem;
  margin-bottom: 4rem;
  line-height: 1.4;
}
.pdf-link {
  font-size: 2rem;
  margin-left: 3rem;
  text-decoration: none;
  font-weight: 400;
  color: #222;
}
.pdf-link ion-icon {
  font-size: 3rem;
  position: absolute;
  transform: translate(-110%, -25%);
}
.pdf-link p {
  color: var(--text-color);
  font-weight: 300;
  margin-top: 0.6rem;
  margin-left: 3rem;
  font-size: 1.6rem;
}
.pdf-download {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10rem;
  max-width: 90rem;
  padding: 1rem;
}
.pdf-download img {
  height: 20rem;
}
.pdf-download .pdf-link {
  padding: 0 0 2rem 2rem;
}

.pdf-download .pdf-link p {
  margin-left: 0;
  font-size: 1.8rem;

  color: #222;
}
@media screen and (max-width: 450px) {
  .pdf-download {
    flex-direction: column;
    align-items: start;
  }
  .pdf-download img {
    margin-bottom: 2rem;
    padding-left: 3rem;
  }
}
