.z-pattern {
  display: flex;
  align-items: center;
  gap: 5rem;
  margin-bottom: 20rem;
  opacity: 0;
  transform: scale(85%);
  transition: 2s;
}
.z-pattern--zoom {
  opacity: 1;
  transform: scale(100%);
  transform: 2s;
}

.z-pattern__img-box {
  width: 55%;
}
.z-pattern__img {
  width: 100%;
  object-fit: cover;
}
.z-pattern__content {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.z-pattern__title {
  text-transform: uppercase;
  font-weight: 200;
  text-align: left;
  font-size: 3rem;
  margin-bottom: 2.4rem;
}

.display-linebreak {
  white-space: pre-line;
}

.btn-more {
  color: #222;
  margin-top: 1rem;
}

@media screen and (max-width: 1200px) {
  .z-pattern__content-right {
    padding-right: 4rem;
  }
  .z-pattern__content-left {
    padding-left: 4rem;
  }
}
@media screen and (max-width: 900px) {
  .name-smaller {
    font-size: 2.8rem;
  }

  .section-aboutus .container {
    padding-left: 0;
    padding-right: 0;
  }
  .z-pattern__content {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .z-pattern {
    flex-direction: column;
    margin-bottom: 10rem;
  }
  .z-pattern__img-box {
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .z-pattern__content {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
