.section-contact {
  background-color: var(--light-bg);
  min-height: 76vh; /* or the minimum height of your content */
}
.contact-list {
  list-style: none;
}
.grid--2-cols {
  grid-template-columns: repeat(2, 1fr);
  gap: 6rem;
}

.form-container h1 {
  margin-bottom: 0;
}
.form-container p {
  margin-bottom: 0;
}
.form-container,
.address-container {
  width: 100%;
}
.address-container h1 span:last-child {
  font-size: 2rem;
}
.address-container h1 span:nth-child(2),
.address-container h1 span:nth-child(3) {
  font-size: 2.5rem;
}
.address-container h1 {
  font-size: 2.8rem;
}
.address-container {
  align-self: center;
  /* border-top: 1px solid black;
  border-bottom: 1px solid black; */
  padding: 1.5rem 0 2rem 0;
  margin-bottom: 8rem;
}
.address-container h1 {
  margin-bottom: 4rem;
  padding-left: 1rem;
}

.contact-list {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-left: 2rem;
}

.contact-items {
  position: relative;
}
.contact-items:first-child {
  /* margin-bottom: 19rem; */
  margin-bottom: 5rem;
}

.contact-link {
  display: flex;
  align-items: center;
  transition: all 300ms ease-in-out;
}
.contact-link:hover {
  transform: translateX(3%);
}

.contact-link:first-child {
  align-items: start;
}

.contact-items:first-child p,
.contact-items:last-child p {
  font-weight: 600;
  line-height: 1.4;
  color: black;
}
.contact-link ion-icon {
  font-size: 2rem;
}

.contact-item {
  font-size: 1.8rem;
  margin-left: 1rem;
  font-weight: 300;
  color: var(--text-color);
}

.pobox {
  margin-top: 0.5rem;
}
.grey-background {
  background-color: var(--light-bg);
}
.form-container {
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  gap: 2rem;

  margin-bottom: 5rem;
}
.form-container textarea {
  border: 1.5px solid black;
  padding: 1.5rem;
  height: 20rem;
  font-size: 1.8rem;
  font-weight: 500;
  font-family: inherit;
}

.form-container input,
.form-container textarea {
  width: 100%;
}
.section-contact .container {
  padding-left: 0;
  padding-right: 0;
}

@media screen and (max-width: 1158px) {
  .address-container {
    margin-bottom: 5rem;
  }
}
@media screen and (max-width: 900px) {
  .grid--2-cols {
    grid-template-columns: 1fr;
    width: 80%;
  }
  .contact-items:first-child {
    margin-bottom: 6rem;
  }
  .address-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .address-container h1 {
    margin-left: 3rem;
  }
  .contact-items:first-child {
    margin-left: -0.8rem;
  }
}
@media screen and (max-width: 600px) {
  .grid--2-cols {
    width: 100%;
  }
  .address-container {
    align-items: start;
    text-align: start;
  }
  .address-container h1 {
    margin-left: 0;
  }
}
@media screen and (max-width: 390px) {
  .address-container h1 {
    font-size: 2.4rem;
  }

  .address-container h1 span:last-child {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 335px) {
  .address-container h1 {
    font-size: 2.2rem;
  }

  .address-container h1 span:last-child {
    font-size: 1.4rem;
  }

  .address-container h1 span:nth-child(2),
  .address-container h1 span:nth-child(3) {
    font-size: 2.2rem;
  }
}
