/* dropdown menu */

.dropdown--menu {
  position: sticky;
  top: 10rem;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2.5rem;
  align-items: center;
  text-align: center;
  width: 100%;
  border-top: 0.1px solid var(--line-color);

  list-style: none;

  min-height: 3.5rem;

  background-color: rgb(0, 0, 0);
  opacity: 0.9;
  z-index: 8;
}

.dropdown--menu li {
  text-decoration: none;
}
.dropdown-link {
  display: block;

  width: 100%;
  height: 100%;

  text-decoration: none;

  font-size: 1.6rem;

  font-weight: 300;
  color: var(--light-grey);

  transition: all 0.3s;
}
.dropdown-link:hover {
  color: #f7f7f7;
}
.dropdown-link.active {
  color: #f7f7f7;
  position: relative;
}

@media screen and (max-width: 960px) {
  .dropdown--menu {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(8rem, 1fr));
    column-gap: 0;
    row-gap: 1rem;
    top: 8rem;
    border-top: none;
    padding-top: 1rem;
  }
  .dropdown--menu li {
    min-height: 4rem;
    width: 100%;
    border-bottom: 0.1px solid var(--line-color);
  }
}
