.closeBtn {
  position: fixed;
  top: 3%;
  right: 1%;
  background-color: transparent;
  border: none;
  z-index: 11;
}

.closeBtn ion-icon {
  font-size: 3rem;
  color: black;
}
.closeBtn:hover ion-icon {
  color: black;
  transform: scale(1);
}
.closeBtn-white ion-icon {
  color: #fff;
}
.closeBtn-white:hover ion-icon {
  color: #fff;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.85);
  width: 100%;
  height: 100%;
  z-index: 10;
}
.modal-container {
  max-width: 120rem;
  width: 100%;
  height: 58%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;

  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
}
.modal-colour-container {
  max-width: 60rem;
  background-color: #fff;
  width: 100%;
  max-height: 100vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  overflow-y: scroll;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
}
@media screen and (max-width: 900px) {
  .modal-container {
    height: 70%;
  }
}
@media screen and (max-width: 600px) {
  .modal-container {
    height: 85vh;
  }
  .modal-colour-container {
    max-height: 100vh;
  }
  .overlay {
    background-color: rgba(0, 0, 0, 0.9);
  }
}
@media screen and (max-width: 460px) {
  .closeBtn {
    top: 0;
    right: 0;
  }
}
