.footer {
  padding: 0;
}
.footer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.social-links {
  list-style: none;
  display: flex;
  justify-content: center;
  gap: 4rem;
  border-top: 1px solid;
  padding: 1.5rem 0;
  margin-bottom: 0;
}

ion-icon {
  font-size: 2.5rem;
  color: #222;
  transition: all 0.2s ease-in-out;
}
ion-icon:hover {
  transform: scale(1.2);
  color: #222;
}
.footer-link:link,
.footer-link:visited {
  color: rgb(168, 166, 163);
  transition: all 0.3s;
}

.footer-link:hover,
.footer-link:active {
  color: #fff;
}

.footer-link--name {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
.footer-icon--name {
  font-size: 1.8rem;
  color: #222;
  margin-left: 0.5rem;
}

.footer-signature {
  background-color: black;
  opacity: 0.9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 2.3rem;
  gap: 2rem;
  padding: 10rem 0;
}
.copyright {
  color: #fff;
  font-size: 1.4rem;

  letter-spacing: 2px;
  text-align: center;
}
.copyright:last-child {
  font-size: 1rem;
}

.symbol {
  font-size: 1.4rem;
}
