.cards__container {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
  grid-gap: 0.2rem;
  padding: 0.3rem;
  padding-bottom: 5rem;
}
.cards__item button {
  display: inline-block;
  width: 100%;
}
.cards__item {
  overflow: hidden;
}
.cards__item__link {
  text-decoration: none;
  z-index: -1;
}

.cards__item__pic-wrap {
  position: relative;

  padding-top: 70%;
  overflow: hidden;
}
.cards__item__img {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}
/* .cards__item__img:hover {
  transform: scale(1.1);
} */
.project-name {
  background-color: var(--text--bg);
  box-sizing: border-box;
  /* max-width: calc((100%) - 60px); */
  padding: 1em 3em;
  position: absolute;
  top: 35%;
  left: 0;

  transform: translateX(-100%);

  transition: all 0.5s;
}
.project-name h1,
.project-name p {
  color: #fff;
}
.project-name h1 {
  font-size: 2em;
}
.project-name p {
  font-size: 1.4em;
}
.cards__item__pic-wrap:hover .project-name {
  transform: translateX(0);
}

.cards__item__pic-wrap::after {
  content: "ALUCOBOND®";
  position: absolute;
  bottom: 0;
  right: 0;

  padding: 1rem 1rem 1rem 1.4rem;
  border-radius: 1rem 0 0 0;
  max-width: calc((100%) - 60px);
  font-size: 1.6rem;

  font-weight: 700;
  color: #fff;
  background-color: #4b4b4bc7;
  box-sizing: border-box;
}
.project--name {
  font-size: 2.7rem;
  font-weight: 600;
  margin-bottom: 0.7rem;
  color: black;
}
.border-line-project--info {
  max-width: 55rem;
}
.project--info {
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  row-gap: 1.5rem;
  max-width: 70rem;
  margin-bottom: 10rem;
}
.project--info li {
  display: flex;
  flex-direction: column;
}
.project--info li {
  font-size: 1.6rem;
  font-weight: 600;
}

.project--info li span:first-child {
  text-transform: uppercase;
  margin-bottom: 0.2rem;
  font-size: 1.8rem;
  font-weight: 200;
}
.project-page-title {
  margin-top: 10rem;
}

@media screen and (max-width: 900px) {
  .cards__container {
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  }
}
@media screen and (max-width: 600px) {
  .project-page-title {
    display: none;
  }
}
@media screen and (max-width: 375px) {
  .project--info {
    grid-template-columns: 1fr;
  }
}
