/* reset */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a {
  text-decoration: none;
  color: black;
  cursor: pointer;
}
ul {
  margin: 0;
  padding: 0;
}
*:focus {
  /* outline: 1px solid black; */
  outline: none;
}
html {
  font-size: 62.5%;
}

body {
  margin: 0;
  padding: 0;

  font-family: "Source Sans Pro", sans-serif;

  font-weight: 200;
  line-height: 1;
  color: #4b4b4b;

  display: block;
}

/* general */
:root {
  --black: #222;
  --white: #fff;
  --line-color: #b3a597;
  --font-text: Helvetica, arial, sans-serif;
  --text-color: #525252;
  --text--bg: rgba(0, 0, 0, 0.65);
  --light-grey: rgb(168, 166, 163);
  --light-bg: #f1f1f1;
  --image-darker: rgba(0, 0, 0, 0.5);
}
/* Text */
/* --------- */

.bodytext {
  font-size: 1.8rem;
  line-height: 1.6;
  font-weight: 300;
  margin-bottom: 1.8rem;
  color: black;
  letter-spacing: 0.4px;
}

.bodytext small {
  font-size: 1rem;
  position: absolute;
}
.headline-container {
  max-width: 120rem;
  margin: 0 auto;
}
.headline {
  align-self: start;
  width: 100%;
  font-size: 5rem;
  margin-bottom: 1rem;
  font-weight: 700;
  color: black;
  letter-spacing: 0.6px;
  line-height: 1.2;
}
.headline-s {
  font-size: 3rem;
  letter-spacing: 0.3px;
}
.headline-light {
  font-size: 2rem;
  font-weight: 400;
}
.headline-light-big {
  font-size: 3rem;
  font-weight: 200;
}

.primary-headline {
  text-transform: uppercase;
  font-weight: 200;
  text-align: center;
  font-size: 3.8rem;
  margin-bottom: 8rem;
}
.uppercase {
  text-transform: uppercase;
}
/* Containers */
/* ----------------------- */

.container {
  max-width: 120rem;
  margin: 0 auto;
}

.border-line {
  width: 100%;
  border-bottom: 1px solid #fff;
  margin-bottom: 3rem;
}

/* boders */
/* ----------------- */
.border-line-black {
  border-bottom: 1px solid black;
}

/* home */
/* ------------------- */

.project-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* all Section Guides */
/* ---------------------- */

.section {
  max-width: 120rem;
  margin: 5rem auto;
  position: relative;
}
.margin-top {
  margin-top: 10rem;
}
.margin-top-15 {
  margin-top: 15rem;
}

.margin-top-5 {
  margin-top: 5rem;
}
.margin-left {
  margin-left: 1rem;
}
.margin-bottom-10 {
  margin-bottom: 10rem;
}
.margin-bottom-20 {
  margin-bottom: 20rem;
}
.margin-bottom-5 {
  margin-bottom: 5rem;
}
.margin-bottom-3 {
  margin-bottom: 3rem;
}

.padding-top-bottom {
  padding: 10rem 0;
}

.section-bottom-spacing {
  margin-bottom: 6rem;
}
/* Sections */
.padding {
  padding: 15rem 0 5rem;
}
.grey-background {
  background-color: var(--light-bg);
}
.image-section {
  width: 100%;
  height: 35vh;
  position: relative;
}
.image-section img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: bottom;
}

/* Button */
/* --------------------- */

.btn {
  display: block;
  cursor: pointer;
  text-decoration: none;
  letter-spacing: 0.63px;
  font-family: var(--font-text);
  font-size: 1.6rem;
  transition: 200ms;
}
.btn-click {
  padding: 2rem 3.6rem;
  font-weight: 600;
}
.btn-black {
  color: #fff;
  background-color: black;
  border: 2px solid black;
}
.btn-black:hover {
  color: black;
  background-color: transparent;
  border: 2px solid black;
}
.btn-white {
  background-color: rgba(255, 255, 255, 0.862);
  border: 2px solid transparent;
  color: black;
}
.btn-white:hover {
  background-color: transparent;
  border: 2px solid #fff;
  color: #fff;
  text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}
.btn-more {
  align-self: flex-start;
  font-weight: 700;
  padding-bottom: 0.3rem;
  letter-spacing: 0.3px;
  border-bottom: 1.5px solid black;
}
.btn-more:hover {
  border-bottom: none;
}
.go-back-btn {
  position: absolute;
  top: 0;
  text-decoration: none;
  padding: 1rem 1.3rem 1rem 1.2rem;
  border: 1.4px solid #222;

  transition: all 0.3s;
}
.go-back-btn:hover {
  background-color: #222;
  color: #fff;
}

.project-section {
  margin-bottom: 5rem;
}
.not-found {
  font-size: 2.5rem;
  font-weight: 600;
  text-transform: none;
  margin-bottom: 60vh;
}
.circle {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: red;
  border-bottom-color: red;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.white-cl {
  color: #fff;
}
/* rear-ventilation css */
.download-section {
  margin: 6rem 0;
}
.images-container {
  margin-top: -5rem;
  margin-bottom: 10rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
}
.image-box {
  justify-self: center;
  width: 50%;
}
.image-box h1 {
  font-size: 2rem;
  letter-spacing: 0.3px;
  margin-bottom: 0;
}
.rear-ventialtion-img-container {
  display: flex;
  justify-content: center;
  margin: 15rem 0;
}
.rear-ventialtion-img {
  width: 70%;
}
.rear-ventilation-list-container {
  margin-left: 5rem;
  margin-bottom: 10rem;
}

/* media query */
/* ----------------------- */

@media (min-width: 1000px) {
  .image-section {
    height: 40vh;
  }
}

@media screen and (max-width: 1200px) {
  .query-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media screen and (max-width: 950px) {
  .margin-bottom-10 {
    margin-bottom: 5rem;
  }
}
@media screen and (max-width: 600px) {
  .rear-ventilation-list-container {
    margin-left: 2rem;
  }
  .margin-top {
    margin-top: 8rem;
  }
  .primary-headline {
    font-size: 3.2rem;
  }
  .image-box {
    width: 70%;
  }
  .rear-ventialtion-img {
    width: 90%;
  }
  .rear-ventialtion-img-container {
    margin: 8rem 0;
  }
  .padding {
    padding: 10rem 0 5rem;
  }
}
@media screen and (max-width: 540px) {
  .images-container {
    grid-template-columns: 1fr;
  }
  .image-box {
    width: 60%;
    margin-bottom: 5rem;
  }
}
